<template>
        <div>
            <div style="display: flex; margin: 15px 5px;">
                <van-button style="width: 9.09091%;" size="mini" v-for="number in 10" :key="number"
                    :type="buttonType(number, list)" @click="setScore(number)">
                    {{ number }}
                </van-button>
            </div>
            <div class="optiondesc">
                <div style="margin-right: 6px; text-align: left;">
                    不可能
                </div>
                <div style="margin-left: 6px; text-align: right;">
                    极有可能
                </div>
            </div>
        </div>
</template>

<script>


export default {
    name: "ModalNps",
    props: {
        score:{
            type:Number,
            default:0,
        },
        optiondesc1:{
            type:String,
            default:"不可能",
        },
        optiondesc2:{
            type:String,
            default:"极有可能",
        }
    },
    data: () => ({
        list: [],
    }),
    methods: {
        setScore(number) {
            this.list = Array.from({ length: number }, (_, index) => index + 1);
            this.$emit('score', number);
            this.$emit('change', number);
        },
    },
    computed: {
        buttonType() {
            return (number, list) => {
                if (number <= list.length) {
                    return 'info'
                }
                else {
                    return 'default'
                }
            }
        },
    },
    created() {
    //100级
   
    this.list = Array.from({ length: this.score }, (_, index) => index + 1);
  },
    


};
</script>


<style lang="less" scoped>
.optiondesc{
    color: #999;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    text-align: justify;
    word-break: break-word;
    margin: 0px 5px 10px 5px;
}
</style>
