<template>
  <ModalNps :score="score"></ModalNps>
</template>

<script>

import ModalNps from '../../components/ModalNps.vue';
export default {
   
    data: () => ({
        score:5,
    }),
    methods: {
        
    },
    components: {
     ModalNps
  },

};
</script>


<style lang="less" scoped>
</style>
